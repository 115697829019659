import React from "react";
import Footer from '../components/footer'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "../css/contact.css"
import "../css/about.css"
import NavBar from '../components/navbar'
export default class Career extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <div>
        <div>
          <NavBar />
        </div>
        <div className="about-banner py-20 w-full vh-half banner-color flex">
                    <div className="container mx-auto relative self-center mt-64">
                      <h1 className="about-banner-title text-7xl md:text-7xl text-white uppercase font-bold text-center mb-12 lg:mb-20 contact-margin">CONTACT</h1>
                        <div className="about-info-strip rounded-xl bg-yellow-400">
                          <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-4 p-2.5">
                <div className="col-span-1 m-2 rounded-xl bg-yellow-400 rounding">
                  <img src="../clock.png" class="contact-img" />
                  <div className="time relative mt-4">
                    <h3 className="open-time text-sm uppercase font-bold text-center">Working Hours</h3>
                    <p className="text-sm md:text-base text-center">Mon - Fri</p>
                    <p className="text-sm md:text-base text-center">9:00am to 5:00pm</p>
                  </div>
                </div>
                <div className="col-span-1 m-2 rounded-xl bg-yellow-400 rounding">
                  <img src="../location.png" class="location1" />
                  <div className="address relative mt-4">
                    <h3 className="text-center text-sm uppercase font-bold ">Address</h3>

                    <p className="text-sm text-center md:text-base">Fitlane Sports,<br />2nd Floor, Pillaiyar Kovil Street,<br />Sir Padmavathi Nagar, Mathiravedu, <br />Thiruverkadu, Chennai - 99.</p>
                  </div>
                </div>
                <div className="col-span-1 m-2 rounded-xl bg-yellow-400 rounding">
                  <img src="../contact.png" class="contact-img" />
                  <div className="phone relative mt-4">
                    <h3 className="text-sm text-center uppercase font-bold ">Contact</h3>
                    <p className="text-sm text-center md:text-base"> business@fitlanesports.com</p>
                    <p className="text-sm text-center md:text-base">+91 7448-32-33-34</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="location-mar">
          <div className="locate-symbol">
            <img src="https://cdn-icons-png.flaticon.com/128/535/535239.png" width='60' height='60' ></img></div>
          <h1 className="uppercase text-center text-yellow-400 text-2xl">Our Location</h1>
          <h4 className="text-center uppercase font-color text-3xl">visit us in our Location</h4>
        </div>
        <div className="ourlocation  ">
          <div id="our-location"></div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3886.530112539733!2d80.1307739!3d13.0655542!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4a4c9bc4f3101d36!2zMTPCsDAzJzU0LjgiTiA4MMKwMDcnNTEuMCJF!5e0!3m2!1sen!2sin!4v1645175305688!5m2!1sen!2sin" width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
        </div>
        <div className="contact-1">
          <img src="../chat.png" width='60' height='60'></img></div>
        <h1 className=" text-center uppercase text-yellow-400 text-2xl">Contact Us</h1>
          <h4 className="text-center uppercase font-color text-3xl p-2.5 lg:p-0">Any futher Information Contact Us</h4>
        <div className="second-grid grid lg:grid-cols-2 grid-cols-1 gap-4 lg:pt-12 pt-0 flex">
          <div className="forms  col-span-1 bg-gray-00">
            <form>
              <div class="space-y-4 mb-8">
                <h1 class="text-center font-color uppercase text-2xl">Contact Form</h1>
                <div>
                  <label for="email" class="mb-1 text-xl">Name</label>
                  <input type="text" class="border-2 px-4 py-4 outline-none rounded-lg w-full" />
                </div>
                <div>
                  <label for="email" class="mb-1 text-xl">Email</label>
                  <input type="text" class="border-2 px-4 py-4 outline-none rounded-lg w-full" />
                </div>
                <div>
                  <label for="email" class="mb-1 text-xl">Message</label>
                  <textarea type="text" class="border-2 h-32 p-4 outline-none rounded-lg w-full" />
                </div>
              </div>
              <a href="#"
                class="bg-transparent uppercase rounded shadow hover:shadow-lg py-2 px-4 btn-color">
                Send Message
              </a>
            </form>
          </div>
          <div className="lg:rounded-xl information-pannel col-span-1 bg-yellow-400 flex">
            <div className="content-icon-panel py-8 self-center">
              <div className="icon-content  pl-12 lg:pl-24 flex flex-row space-x-8 justify-between mb-4">
                <span className="display-span">
                  <a target="_blank" href="https://www.google.com/maps/place/13%C2%B003'54.8%22N+80%C2%B007'51.0%22E/@13.0655542,80.1307739,17z/data=!4m13!1m7!3m6!1s0x0:0x4a4c9bc4f3101d36!2zMTPCsDAzJzU0LjgiTiA4MMKwMDcnNTEuMCJF!3b1!8m2!3d13.0652221!4d80.1308334!3m4!1s0x0:0x4a4c9bc4f3101d36!8m2!3d13.0652221!4d80.1308334?hl=en">
                    <img src="https://cdn-icons-png.flaticon.com/128/535/535239.png " className="w-10 h-12 pt-2" />
                  </a>
                </span>
                <span className="display-span self-center">
                  <a target="_blank" href="https://www.google.com/maps/place/13%C2%B003'54.8%22N+80%C2%B007'51.0%22E/@13.0655542,80.1307739,17z/data=!4m13!1m7!3m6!1s0x0:0x4a4c9bc4f3101d36!2zMTPCsDAzJzU0LjgiTiA4MMKwMDcnNTEuMCJF!3b1!8m2!3d13.0652221!4d80.1308334!3m4!1s0x0:0x4a4c9bc4f3101d36!8m2!3d13.0652221!4d80.1308334?hl=en" className="paragraph">Fitlane Sports,<br />2nd Floor, Pillaiyar Kovil Street,<br />Sir Padmavathi Nagar, Mathiravedu, <br />Thiruverkadu, Chennai - 99.</a>
                </span>
              </div>

              <div className="icon-content pl-12 lg:pl-24 flex flex-row space-x-8 mb-4">
                <span className="display-span">

                  <a target="_blank" href="mailto: business@fitlanesports.com"><img src="https://cdn-icons-png.flaticon.com/128/2165/2165061.png" className="w-10 h-12 pt-2" /></a>
                </span>
                <span className="display-span self-center">
                  <a target="_blank" href="mailto: business@fitlanesports.com" className="paragraph">business@fitlanesports.com</a>
                </span>


              </div>


              <div className="icon-content  pl-12 lg:pl-24 flex flex-row space-x-8 mb-2">
                <span className="display-span">

                  <a target="_blank" href="tel:+91 7448-32-33-34"><img src="../call.png" className="w-10 h-12 pt-2" /></a>
                </span>
                <span className="display-span self-center">
                  <a target="_blank" href="tel:+91 7448-32-33-34" className="paragraph">+91 7448-32-33-34</a>
                </span>
              </div>
            </div>

          </div >

        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}